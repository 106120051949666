import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";

const PredPieChart = (props) => {
  const { data, width = 320, height = 320 } = props;
  const COLORS = ["#0088FE88", "#00C49F88", "#FFBB2888", "#FF804288"];
  const { isMobileScreen } = useScreenSizeContext();
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderPieChart = () => {
    if (data && data.length)
      return (
        <PieChart width={width} height={height}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getDynamicColor(index)} />
            ))}
          </Pie>
        </PieChart>
      );
    return <p>No data found</p>;
  };

  const getDynamicColor = (index) => COLORS[index % COLORS.length];

  const renderCustomLegend = () => {
    return (
      <div className="d-flex flex-column gap-2">
        {data.map((item, index) => {
          const backgroundColor = getDynamicColor(index);
          return (
            <div className="pie-legend" key={`pie-legend-${index}`}>
              <div className="pie-legend-box" style={{ backgroundColor }}></div>
              <p className="m-0 p-0">{item.name}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const render = () => {
    return (
      <div
        className={`d-flex gap-2 align-items-center ${isMobileScreen ? "flex-column" : ""}`}
      >
        {renderPieChart()}
        {renderCustomLegend()}
      </div>
    );
  };

  return render();
};

export default PredPieChart;
