import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../context/app.context";
import constant, { TemplateStatus } from "../helper/constant";
import images from "../helper/images";
import { useAIModelContext } from "../context/ai-model.context";

const SideMenu = () => {
  const { showLeftMenu, updateLeftMenu } = useAppContext();
  const { activeTemplate } = useAIModelContext();
  const [maskHeight, setMaskHeight] = useState("10%");
  const route = useLocation();
  const params = useParams();
  useEffect(() => {
    if (activeTemplate && activeTemplate.status === TemplateStatus.SCHEDULED) {
      setMaskHeight(475);
      return;
    }
    if (activeTemplate && activeTemplate.status === TemplateStatus.COMPLETED) {
      setMaskHeight(610);
      return;
    }
    if (route.pathname.includes(constant.PAGES.HOME.AI_WORKFLOW)) {
      setMaskHeight(205);
      return;
    }
    if (route.pathname.includes(constant.PAGES.HOME.AI_VALUE)) {
      setMaskHeight(260);
      return;
    }
    if (route.pathname.includes(constant.PAGES.HOME.AI_ROADMAP)) {
      setMaskHeight(310);
      return;
    }
    if (route.pathname.includes(constant.PAGES.HOME.AI_DATA)) {
      setMaskHeight(415);
      return;
    }
    if (route.pathname.includes(constant.PAGES.HOME.AI_MODEL)) {
      setMaskHeight(465);
      return;
    }
    setMaskHeight(100);
  }, [route.pathname, activeTemplate]);

  return (
    <section
      className={`leftbar text-white px-3 py-3 ${showLeftMenu ? "active" : ""}`}
    >
      <div className="activeoverlaybar" style={{ top: maskHeight }}></div>
      <nav className="nav active ">
        <div className="mobilemnuclswrp d-flex flex-column justify-content-between">
          <ul className="navlist ">
            <li>
              <NavLink to="/">
                <img src={images.dashbord} alt={constant.PAGES.HOME.HOME} />
                <span>Home </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${constant.PAGES.HOME.BUSINESS_OVERVIEW}`}>
                <img
                  src={images.businessOverview}
                  alt={constant.PAGES.HOME.BUSINESS_OVERVIEW}
                />
                <span>Business Overview </span>
              </NavLink>
            </li>
            <li>
              <a
                href="#link-ai-strategy"
                data-toggle="collapse"
                aria-expanded="true"
                className="blue-thin-bar"
              >
                <img src={images.aiStrategy} alt="AI-Strategy" />
                <span>AI Strategy</span>{" "}
              </a>
              <ul className="collapse list-unstyled show" id="link-ai-strategy">
                <li>
                  <NavLink
                    to={`/${constant.PAGES.HOME.AI_WORKFLOW}/${params.id}`}
                    className="navlink-active"
                  >
                    <img
                      src={images.aiWorkFlow}
                      alt={constant.PAGES.HOME.AI_WORKFLOW}
                    />
                    <span>AI Workflow</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${constant.PAGES.HOME.AI_VALUE}/${params.id}`}
                    className="nanavlinkv-active"
                  >
                    <img
                      src={images.aiValue}
                      alt={constant.PAGES.HOME.AI_VALUE}
                    />
                    <span>AI Value</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${constant.PAGES.HOME.AI_ROADMAP}/${params.id}`}
                    className="navlink-active"
                  >
                    <img
                      src={images.aiRoadmap}
                      alt={constant.PAGES.HOME.AI_VALUE}
                    />
                    <span>AI Roadmap</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#link-ai-transform"
                data-toggle="collapse"
                aria-expanded="true"
                className="blue-thin-bar"
              >
                <img src={images.aiTransformation} alt="AI-Transformation" />
                <span>AI Transformation</span>{" "}
              </a>
              <ul
                className="collapse show list-unstyled"
                id="link-ai-transform"
              >
                <li>
                  <NavLink to={`/${constant.PAGES.HOME.AI_DATA}/${params.id}`}>
                    <img src={images.aiOps} alt="AI Data" />
                    <span>AI Data</span>{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${constant.PAGES.HOME.AI_MODEL}/${params.id}`}>
                    <img src={images.aiModel} alt="AI Model" />
                    <span>AI Model</span>{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink href="#">
                    <img src={images.aiApplication} alt="AI Application" />
                    <span>AI Application</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to={`/${constant.PAGES.AI_PREDICTION}/${params.id}`}
                className="blue-thin-bar"
              >
                <img src={images.aiModel} alt="AI Prediction" />
                <span>AI Prediction</span>{" "}
              </NavLink>
            </li>
            <li className="pt-5">
              <NavLink
                to={`/${constant.PAGES.HOME.AI_TRAINING}`}
                className="blue-thin-bar"
              >
                <img src={images.aiStrategy} alt="AI Training" />
                <span>AI Training</span>{" "}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <div
        className={`iconclic2 ${showLeftMenu ? "iconactive" : ""}`}
        onClick={() => updateLeftMenu(!showLeftMenu)}
      >
        <img src={images.sidepanelArrow} alt="" />
      </div>
    </section>
  );
};

export default SideMenu;
