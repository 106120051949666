import React from "react";
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { stringToHslColor } from "../../../helper/helper";

const PredLineChart = (props) => {
  const { config } = props;
  const renderXAxis = () => <XAxis dataKey={config.datakeys[0]} />;

  const renderYAxis = () => {
    return <YAxis />;
  };

  const renderLegend = () => {
    return <Legend verticalAlign="bottom" height={36} />;
  };

  const renderChartData = () => {
    const backgroundColor = stringToHslColor(config.labels[1]);
    return (
      <Line
        type="monotone"
        dataKey={config.datakeys[1]}
        stroke={backgroundColor}
        activeDot={{ r: 8 }}
      />
    );
  };

  const render = () => {
    if (config && config.data && config.data.length)
      return (
        <div className="d-flex flex-column gap-1 text-center">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              data={config.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {renderXAxis()}
              {renderYAxis()}
              {config.tooltip ? (
                <Tooltip content={config.tooltip} />
              ) : (
                <Tooltip />
              )}
              {renderLegend()}
              {renderChartData()}
              <Brush />
            </LineChart>
          </ResponsiveContainer>
          <h4 className="">{config.title}</h4>
        </div>
      );
    return <p>No data found</p>;
  };
  return render();
};

export default PredLineChart;
