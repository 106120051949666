import React, { useEffect, useState } from "react";
import constant from "../../../helper/constant";
import { getAnalyticsDashboardURL } from "../../../service/ai-model.service";
import CustomLoader from "../../common/CustomLoader";
import { useAppContext } from "../../../context/app.context";

const AIInference = () => {
  const [url, setURL] = useState("");
  const [loading, setLoading] = useState(true);
  const { updateLeftMenu } = useAppContext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const viewId = params.get("viewId");
    const id = params.get("id");
    const taskId = params.get("taskId");
    updateLeftMenu(false);
    getAnalyticsDashboardURL(id, taskId, viewId)
      .then((link) => setURL(link))
      .catch(() => setURL(null))
      .finally(() => setLoading(false));
  }, []);

  const render = () => {
    if (loading) {
      return (
        <p className="ai-inference-frame d-flex align-items-center justify-content-center">
          <CustomLoader />
        </p>
      );
    }
    if (url) {
      return (
        <div className="d-flex flex-column gap-2 p-3">
          <h3>Visualize AI</h3>
        <iframe
          src={url}
          onError={(e) => console.log(e)}
          className="ai-inference-frame pb-5"
          allowFullScreen
        />
        </div>
      );
    }
    return (
      <p className="error-msg text-center p-5">
        {constant.MESSAGES.COMMON_ERROR_MESSAGE}
      </p>
    );
  };

  return render();
};

export default AIInference;
