import React, { useEffect, useState } from "react";
import useInput from "../../hooks/useInput";
import { useAuthContext } from "../../context/auth.context";
import { useAppContext } from "../../context/app.context";
import { getTenantList } from "../../service/auth.service";
import CustomLoader from "../common/CustomLoader";
import Recapcha from "./Recapcha";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateCompanyName, validateEmail } from "../../helper/helper";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ToastType } from "../../helper/constant";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const LoginForm = () => {
  const { login, createOrgFirstTime, authError, requestPasswordReset } =
    useAuthContext();
  const { isMobileScreen } = useScreenSizeContext();
  const { showAlert } = useAppContext();
  const [loading, setLoading] = useState();
  // rohit.abraham02@gmail.com
  // Bitwise@01
  // incorp-1zfpt
  const [username, bindUsername, resetUsername] = useInput("");
  const [password, bindPassword, resetPassword] = useInput("");
  const [company, bindCompany, resetCompany] = useInput("");
  const [TenantList, setTenantList] = useState([]);
  const [form, setForm] = useState({ error: {} });
  const [isResetDone, setIsResetDone] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (username) {
        setLoading(true);
        // Send Axios request here
        getTenantList(username)
          .then((r) => {
            resetCompany();
            setTenantList(r);
          })
          .catch((ex) => console.log(ex))
          .finally((ex) => setLoading(false));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [username]);

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!username) {
      formIsValid = false;
      error["username"] = "Username is required.";
    }
    if (username && !validateEmail(username, true)) {
      formIsValid = false;
      error["username"] =
        "Enter valid username (email/phone no). Please contact admin";
    }
    if (!password) {
      formIsValid = false;
      error["password"] = "Password is required.";
    }
    if (!company) {
      formIsValid = false;
      error["company"] = "Company is required.";
    }
    if (!validateCompanyName(company) && TenantList.length === 0) {
      formIsValid = false;
      error["company"] = "No special charecters allowed.";
    }
    resetPassword();
    setForm({ error });
    return formIsValid;
  };

  const submitPasswordReset = () => {
    let error = {};
    if (!username) {
      error["username"] = "Username is required.";
      setForm({ error });
      return false;
    }
    if (username && !validateEmail(username)) {
      error["username"] = "Enter valid username (email/phone no).";
      setForm({ error });
      return false;
    }
    setLoading(true);
    requestPasswordReset(
      username,
      company,
      () => {
        setForm({ error });
        clearForm();
        setIsResetDone(true);
        setLoading(false);
      },
      true,
      (ex) => {
        setLoading(false);
        showAlert(ex?.message || ex, ToastType.ERROR);
      }
    );
  };

  const renderResetMessage = () => {
    if (isResetDone)
      return (
        <div className="loginfailedbox message-alert-success my-3 p-3 d-flex align-items-center justify-content-between gap-1">
          <p className="m-0 p-0">Reset request sent. Please check your email</p>
          <Link onClick={() => setIsResetDone(false)}>
            <FontAwesomeIcon icon={faClose} className="" size="lg" />
          </Link>
        </div>
      );

    if (!isResetDone && authError)
      return (
        <div className="loginfailedbox my-3 p-3 d-flex align-items-center">
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 69 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="34.5"
                cy="35.3682"
                r="33.5"
                stroke="#878585"
                strokeWidth="2"
              />
              <line
                x1="19.8592"
                y1="19.9111"
                x2="49.9575"
                y2="50.0094"
                stroke="black"
              />
              <line
                y1="-0.5"
                x2="42.5655"
                y2="-0.5"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 49.6033 20.2646)"
                stroke="black"
              />
            </svg>
          </div>
          <div className="ml-3">
            Sorry, the Username & Password doesn't match. We can help you to{" "}
            {renderGeneratePassword()}
          </div>
        </div>
      );
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (!TenantList || TenantList.length === 0) {
        const payload = { org_name: company, org_email: username, password };
        createOrgFirstTime(payload, true);
      } else login({ username, password, company });
    }
  };

  const clearForm = () => {
    resetUsername();
    resetPassword();
    resetCompany();
    setForm({ error: {} });
  };

  const renderLogin = () => {
    return (
      <button className="btn1 mt-3 font-weight-bold w-100" type="submit">
        SIGN IN
      </button>
    );
  };

  const renderTenentList = () => {
    if (TenantList && TenantList.length)
      return (
        <div className="customselect ">
          <select
            className={`form-control ${form.error["company"] ? "error-br" : ""}`}
            {...bindCompany}
          >
            <option value="">Select Company Name</option>
            {TenantList.map((opt) => (
              <option key={opt.org_tenant} value={opt.org_tenant}>
                {opt.org_name}
              </option>
            ))}
          </select>
        </div>
      );
    return (
      <input
        type="text"
        className={`form-control ${form.error["company"] ? "error-br" : ""}`}
        {...bindCompany}
        placeholder="Company Name"
      />
    );
  };

  const onVerification = (e) => {};

  const renderGeneratePassword = () => {
    return (
      <Link onClick={() => submitPasswordReset()} className="text-blue">
        Recover your password
      </Link>
    );
  };

  return (
    <>
      {loading && <CustomLoader full />}
      <section className="loginwrap d-flex align-items-center p-1 justify-content-cetnter">
        <div
          className={`mx-auto px-0 px-4 py-3 bg-white ${isMobileScreen ? "loginbox-mob" : "loginbox"}`}
        >
          <h3 className="h5 my-3 text-center font-weight-bold">Signup/Login</h3>
          {renderResetMessage()}
          <form onSubmit={onFormSubmit}>
            <div className="form-group">
              <input
                type="text"
                className={`form-control ${form.error["username"] ? "error-br" : ""}`}
                {...bindUsername}
                placeholder="Email / Phone"
              />
              {form.error["username"] && (
                <small className="text-danger">{form.error["username"]}</small>
              )}
            </div>
            <div className="form-group">
              {renderTenentList()}
              {form.error["company"] && (
                <small className="text-danger">{form.error["company"]}</small>
              )}
            </div>
            <div className="form-group mb-1">
              <input
                type="password"
                className={`form-control ${form.error["password"] ? "error-br" : ""}`}
                {...bindPassword}
                placeholder="Password"
              />
              {form.error["password"] && (
                <small className="text-danger">{form.error["password"]}</small>
              )}
            </div>
            {username &&
              TenantList.length > 0 &&
              company &&
              renderGeneratePassword()}
            <div className="p-3">
              <Recapcha onVerification={onVerification} />
            </div>
            {renderLogin()}
          </form>
        </div>
      </section>
    </>
  );
};

export default LoginForm;
