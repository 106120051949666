import React, { useState } from "react";
import { useAIModelContext } from "../../context/ai-model.context";
import constant, { TemplateStatus } from "../../helper/constant";
import {
  formatToCurrency,
  getDividerLeft,
  getTotalDynamicWidth,
} from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

const Pipe = ({ index, model }) => {
  const [opacity, setOpacity] = useState(model?.department ? 1 : 0.5);
  const { activeTemplate } = useAIModelContext();
  const {
    models,
    addModel,
    deleteModel,
    onSelectModel,
    aiValue,
    isShowModel,
    isAIModelFlow,
    aiDataFormValues,
    isAIPredict,
    updatePredictionModel,
  } = useAIModelContext();

  const onModelSeleted = (e) => {
    if (
      (activeTemplate && activeTemplate.status === TemplateStatus.SCHEDULED) ||
      isAIModelFlow ||
      isAIPredict ||
      isShowModel
    )
      return;
    setOpacity(1);
    onSelectModel(model);
  };

  const onTaskSelected = (e, task) => {
    e.stopPropagation();
    if (activeTemplate && activeTemplate.status === TemplateStatus.SCHEDULED)
      return;
    if (isAIModelFlow && task.id) onSelectModel(task, true);
  };

  const onModelAdd = (e) => {
    e.stopPropagation();
    addModel(index);
  };

  const onModelDelete = (e) => {
    e.stopPropagation();
    deleteModel(model.id);
  };

  const onPredictionClick = (e, task) => {
    e.stopPropagation();
    if (isAIPredict) {
      updatePredictionModel(task);
      return;
    }
  };

  const renderTaskDivider = (isFirst, modelIndex, dynamicWidth) => {
    const { tasks, id } = model;
    if (tasks && tasks.length) {
      const newTaskList = [...tasks];
      return newTaskList.map((task, index) => {
        const formValue = aiDataFormValues.filter(
          (item) => item.id === task.taskId
        );
        const { name, expense, task_color, task_model } = task;
        const isFirstTask = index === 0;
        const isLastTask = index === tasks.length - 1;
        const effortWidth = getDividerLeft(expense);
        const cursor = isAIModelFlow || isAIPredict ? "pointer" : "unset";
        const { template_image } = model;

        const dynamicStyle = {
          width: effortWidth,
        };
        const formStatus = formValue.length;
        const formDataStatusColor = formStatus ? "#217CE8" : "black";
        const predictionStatus =
          formValue.length && formValue[0].status === TemplateStatus.COMPLETED;
        const predictionColor = predictionStatus ? "#007BFF" : "gray";

        return (
          <div
            key={`task-${modelIndex}${index}`}
            className={`funelcontnt position-relative ${
              isFirst ? "first-item" : ""
            } ${isFirstTask ? "first-task" : ""} ${
              isLastTask ? "last-item" : ""
            }`}
            style={
              template_image
                ? {
                    ...dynamicStyle,
                    backgroundImage: `url(${template_image})`,
                  }
                : dynamicStyle
            }
          >
            <div className="font-12 funlsecncontent font-weight-bold absolute-full-width">
              {name}
            </div>
            <div className="font-14 font-weight-bold funlmidcontent absolute-full-width">
              {formatToCurrency(expense)}
            </div>

            {/* <div className="funlsecnlstcontent d-flex align-items-center absolute-full-width justify-content-center">
              <div className="cursor-pointer">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.46875 11.25C7.6875 11.25 7.8725 11.1744 8.02375 11.0231C8.175 10.8719 8.25042 10.6871 8.25 10.4688C8.24958 10.2504 8.17417 10.0654 8.02375 9.91375C7.87333 9.76209 7.68833 9.68667 7.46875 9.6875C7.24917 9.68834 7.06438 9.76396 6.91438 9.91438C6.76438 10.0648 6.68875 10.2496 6.6875 10.4688C6.68625 10.6879 6.76188 10.8729 6.91438 11.0238C7.06688 11.1746 7.25167 11.25 7.46875 11.25ZM6.90625 8.84375H8.0625C8.0625 8.5 8.10167 8.22917 8.18 8.03125C8.25833 7.83334 8.47958 7.5625 8.84375 7.21875C9.11458 6.94792 9.32813 6.69 9.48438 6.445C9.64063 6.2 9.71875 5.90584 9.71875 5.5625C9.71875 4.97917 9.50521 4.53125 9.07813 4.21875C8.65104 3.90625 8.14583 3.75 7.5625 3.75C6.96875 3.75 6.48708 3.90625 6.1175 4.21875C5.74792 4.53125 5.49 4.90625 5.34375 5.34375L6.375 5.75C6.42708 5.5625 6.54438 5.35938 6.72688 5.14063C6.90938 4.92188 7.18792 4.8125 7.5625 4.8125C7.89583 4.8125 8.14583 4.90375 8.3125 5.08625C8.47917 5.26875 8.5625 5.46917 8.5625 5.6875C8.5625 5.89584 8.5 6.09125 8.375 6.27375C8.25 6.45625 8.09375 6.62542 7.90625 6.78125C7.44792 7.1875 7.16667 7.49479 7.0625 7.70313C6.95833 7.91146 6.90625 8.29167 6.90625 8.84375ZM7.5 13.75C6.63542 13.75 5.82292 13.586 5.0625 13.2581C4.30208 12.9302 3.64063 12.4848 3.07813 11.9219C2.51563 11.359 2.07042 10.6975 1.7425 9.9375C1.41458 9.1775 1.25042 8.365 1.25 7.5C1.24958 6.635 1.41375 5.8225 1.7425 5.0625C2.07125 4.3025 2.51646 3.64104 3.07813 3.07813C3.63979 2.51521 4.30125 2.07 5.0625 1.7425C5.82375 1.415 6.63625 1.25084 7.5 1.25C8.36375 1.24917 9.17625 1.41334 9.9375 1.7425C10.6988 2.07167 11.3602 2.51688 11.9219 3.07813C12.4835 3.63938 12.929 4.30084 13.2581 5.0625C13.5873 5.82417 13.7513 6.63667 13.75 7.5C13.7488 8.36334 13.5846 9.17584 13.2575 9.9375C12.9304 10.6992 12.4852 11.3606 11.9219 11.9219C11.3585 12.4831 10.6971 12.9285 9.9375 13.2581C9.17792 13.5877 8.36542 13.7517 7.5 13.75Z"
                    fill="#007BFF"
                  />
                </svg>
              </div>
            </div> */}

            {isAIModelFlow && (
              <>
                <div className="funlsecnuploadcontent d-flex align-items-center absolute-full-width justify-content-center">
                  <Link
                    className={`datatbox ${formStatus ? "cursor-none" : ""}`}
                    onClick={(e) => onTaskSelected(e, task)}
                  >
                    <div
                      className={`${formStatus ? "" : "pophovetool pophovetoolhovr cursor-pointer"}`}
                    >
                      {formStatus ? (
                        <svg
                          width="18"
                          height="17"
                          viewBox="0 0 22 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 5C19 7.21 14.97 9 10 9C5.03 9 1 7.21 1 5M19 5C19 2.79 14.97 1 10 1C5.03 1 1 2.79 1 5M19 5V10M1 5V10M1 10C1 12.21 5.03 14 10 14M1 10V15C1 17.21 5.03 19 10 19"
                            stroke="#007BFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16H14V21H20V16H22L17 12L12 16Z"
                            fill="#217CE8"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="17"
                          viewBox="0 0 22 21"
                          fill="none"
                        >
                          <path
                            d="M19 5C19 7.21 14.97 9 10 9C5.03 9 1 7.21 1 5M19 5C19 2.79 14.97 1 10 1C5.03 1 1 2.79 1 5M19 5V10M1 5V10M1 10C1 12.21 5.03 14 10 14M1 10V15C1 17.21 5.03 19 10 19"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16H14V21H20V16H22L17 12L12 16Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </div>
                  </Link>
                </div>
              </>
            )}
            <div className="font-12 funllstcontent font-weight-bold absolute-full-width">
              {task_model}
            </div>
            {(isShowModel || isAIModelFlow || isAIPredict) && (
              <div
                className="funelvideo cursor-pointer"
                onClick={(e) => predictionStatus && onPredictionClick(e, task)}
                style={
                  predictionStatus
                    ? {}
                    : { cursor: "not-allowed", pointerEvents: "unset" }
                }
              >
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23Z"
                    fill={predictionColor}
                  />
                  <path
                    d="M16 11.9999L10 16.3299V7.66992L16 11.9999Z"
                    fill={predictionColor}
                  />
                </svg>
              </div>
            )}
          </div>
        );
      });
    } else return getDefaultTask(isFirst, dynamicWidth);
  };

  const getDefaultTask = (isFirst, dynamicWidth) => {
    return (
      <div
        key={`task-def`}
        className={`funelcontnt position-relative ${
          isFirst ? "first-item" : ""
        } last-item`}
        style={{ width: dynamicWidth }}
      ></div>
    );
  };
  const render = () => {
    if (!model) return <p>Loading...</p>;
    const isFirst = index === 0;
    const isLast = index === models.length;
    let pointerEvents =
      aiValue ||
      (activeTemplate && activeTemplate.status === TemplateStatus.SCHEDULED)
        ? "none"
        : "unset";
    let hidePermanent = {};
    let transparentStyle = {};
    if (isAIModelFlow || isAIPredict || isShowModel) {
      hidePermanent = { display: "none" };
    }

    const zIndex = models.length ? models.length - index : 1;
    const { departmentName, icon, template_image } = model;
    let dynamicWidth = constant.minCylinderWidth;
    if (model.tasks && model.tasks.length)
      dynamicWidth = getTotalDynamicWidth(model.tasks);
    return (
      <div
        className="d-flex flex-column ai-model"
        key={`pipe-${index}`}
        onClick={onModelSeleted}
      >
        <button
          className="hover-btn ai-btn-add"
          onClick={onModelAdd}
          style={hidePermanent}
        >
          <FontAwesomeIcon icon={faPlus} className="text-white" />
        </button>
        <div className="position-relative fuelhdng d-flex align-items-center justify-content-center">
          <div>
            {icon ? (
              <img className="ai-pipe-icon" src={icon} alt={departmentName} />
            ) : (
              <div style={{ height: 22 }}></div>
            )}
          </div>
          <div
            className="ml-2 font-12 font-weight-bold txt-elipises"
            // style={{ width: dynamicWidth - 30 }}
          >
            {departmentName} {""}
          </div>
        </div>
        <div className="d-flex align-items-center h-100">
          {renderTaskDivider(isFirst, index, dynamicWidth)}
        </div>
        <button
          className="hover-btn ai-btn-delete"
          onClick={onModelDelete}
          style={hidePermanent}
        >
          <FontAwesomeIcon icon={faTrashCan} className="text-white" />{" "}
        </button>
      </div>
    );
  };

  return render();
};

export default Pipe;
