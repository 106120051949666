import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/auth.context";
import {
  createBusinessOverview,
  createDynamicWorkflow,
  getBusinessOverview,
} from "../../../service/ai-model.service";
import { useAppContext } from "../../../context/app.context";
import { useAIModelContext } from "../../../context/ai-model.context";
import CustomLoader from "../../common/CustomLoader";
import { getSolutionList } from "../../../service/auth.service";
import images from "./../../../helper/images.js";
import {
  calculatePercentage,
  covertObjectToArary,
  formatDate,
  formatToCurrency,
} from "../../../helper/helper";
import constant, { ToastType } from "../../../helper/constant";
import PredDonutChart from "../../common/charts/PredDonutChart.js";
import { useScreenSizeContext } from "../../../context/mobile-layout.context.js";

const BusinessOverview = () => {
  const navigate = useNavigate();
  const { userInfo, calculateUnsignedRevenue, revenueReport } =
    useAuthContext();
  const { updateLoader, showAlert, isLoading } = useAppContext();
  const {
    updateAutoCreate,
    updateIsOverviewFirstTime,
    isOverviewFirstTime,
  } = useAIModelContext();
  const [selectedCompany, setSelectedCompany] = useState({ name: "", id: "" });
  const [category, setCategory] = useState();
  const [OtherViewList, setOtherViewList] = useState([]);
  const [lang, setLang] = useState("en");
  const [bo, setBo] = useState();
  const [tourGuide, setTourGuide] = useState({
    showAlert: isOverviewFirstTime,
    showGuide: false,
    step1: false,
    step2: false,
    step3: false,
  });
  const reportRef = useRef(null);
  const [solutionList, setSolutionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (userInfo.org.industry) {
      getSolutionList(userInfo.org.industry)
        .then((list) => setSolutionList(list))
        .finally(() => {});
    }
  }, [userInfo.org.industry]);

  useEffect(() => {
    const { org } = userInfo;
    if (org.org_config) {
      const { industry, revenue, empCount, inventory } = org.org_config;
      const payload = {
        industryId: industry,
        revenue,
        count: empCount,
        inventory: inventory,
        capital_expenditure: 1000,
      };
      calculateUnsignedRevenue(payload);
    }
  }, []);

  useEffect(() => {
    if (userInfo.org.org_config) {
      updateLoader(true);
      setSelectedCompany({ name: "", id: "" });
      setCategory("");
      setCompanyList([]);
      getBusinessOverview({ type: `info-${lang}` })
        .then((result) => handleBoResponse(result, false))
        .catch(() => generateBusinessOverview());
    }
  }, [userInfo.org.org_config, lang]);

  useEffect(() => {
    if (selectedCompany) {
      const otherInfo = OtherViewList.filter(
        (item) => item.id === selectedCompany.name
      );
      if (otherInfo.length) {
        handleBoResponse(otherInfo);
      }
    }
  }, [selectedCompany]);

  const generateBusinessOverview = () => {
    const companyName = userInfo.org.org_config.companyName;
    let payload = {
      website: userInfo.org.org_config.companyUrl,
      company_name: companyName,
      revenue: userInfo.org.org_config.revenue,
      employee_count: userInfo.org.org_config.empCount,
    };
    createBusinessOverview(payload)
      .then((result) => handleBoResponse(result, true))
      .catch((ex) => showAlert(ex?.message, ToastType.ERROR))
      .finally(() => updateLoader(false));
  };

  const handleBoResponse = (result, isFirstTime) => {
    try {
      if (result && result.length === 0) {
        generateBusinessOverview();
        showAlert(
          "Your overview is getting generated. Once its available we will let you know.",
          ToastType.WARNING,
          false
        );
        navigate(`/`);
      } else if (result.length) {
        const data = covertObjectToArary(result[0].data);
        console.log(data);

        setBo(data);
        updateIsOverviewFirstTime(isFirstTime);
        updateLoader(false);
      }
    } catch (ex) {
      console.log(ex);
      updateLoader(false);
      showAlert("Something wrong. Please try again later", ToastType.ERROR);
    }
  };

  const renderBoList = () => {
    if (bo && bo.length) {
      const temp = bo.map((item, index) => {
        const { key } = item;
        return (
          <li className="nav-item" key={key}>
            <a
              className={`nav-link capitalize ${index === 0 ? "active" : ""}`}
              id={`${key}-tab`}
              data-toggle="tab"
              href={`#${key.replaceAll(" ", "_")}`}
              role="tab"
              aria-controls={key}
              aria-selected="false"
            >
              {key.replaceAll("_", " ")}
            </a>
          </li>
        );
      });
      temp.push(
        <li className="nav-item" key={"AIOpportunity-tab"}>
          <a
            className={`nav-link capitalize`}
            id={`AIOpportunity-tab`}
            data-toggle="tab"
            href={`#AIOpportunity`}
            role="tab"
            aria-controls={"AIOpportunity-tab"}
            aria-selected="false"
          >
            AI Opportunity
          </a>
        </li>
      );
      return temp;
    }
  };

  const renderBOHeader = () => {
    return (
      <div className="popuptabs mb-3 bo">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {renderBoList()}
        </ul>
      </div>
    );
  };

  const onCreateWorkflow = () => {
    updateLoader(true);
    const payload = {
      template_name: `${userInfo.org.org_name}-Auto`,
      industryId: userInfo.org.org_config.industry,
    };

    createDynamicWorkflow(payload)
      .then((result) => {
        if (result) {
          updateAutoCreate(true);
          navigate(
            `/${constant.PAGES.HOME.AI_WORKFLOW}/${result.id}?mode=auto`
          );
        }
      })
      .catch((ex) => showAlert(ex?.message, ToastType.ERROR))
      .finally(() => updateLoader(false));
  };

  const renderAiSolutions = () => {
    if (solutionList && solutionList.length) {
      return solutionList.map((item) => {
        const { id, feature, icon_url } = item;
        return (
          <li
            key={`feature-${id}`}
            className="capitalize"
            style={{ "--img": `url(${icon_url})` }}
          >
            {feature}
          </li>
        );
      });
    }
  };

  const renderAiTimeline = () => {
    return (
      <section className="resultweeks">
        <div className="container position-relative">
          <h3 className="text-center mb-5 pb-5">Get results in weeks</h3>
          <div className="position-relative mt-5">
            <img src={images.unsigned.imgTimeline} alt="" className="w-100" />
            <div className="timelinebox timelinebox1">
              <div className="bold h4 text-blue">AI Training</div>
              <p>
                Empower your team to succeed with AI. Our training programs
                provide the knowledge and skills your team needs to demystify AI
                and start using it to drive growth.
              </p>
              <div className="bold text-secondary h4">2 Hours</div>
            </div>
            <div className="timelinebox timelinebox2">
              <div className="bold text-secondary h4">4 Hours</div>
              <p>
                Transform your business with AI. Our AI platform helps you to
                develop a custom AI strategy that aligns with your business
                goals and drives results.
              </p>
              <div className="bold h4 text-blue">AI Strategy</div>
            </div>
            <div className="timelinebox timelinebox3">
              <div className="bold h4 text-blue">AI Transformation</div>
              <p>
                Maximize the impact of AI on your business. Our team of AI
                experts works with you to implement your strategy and transform
                your operations for optimal results.
              </p>
              <div className="bold text-secondary h4">1-3 Days</div>
            </div>
            <div className="timelinebox timelinebox4">
              <div className="bold text-secondary h4">As Requested</div>
              <p>
                Integrate AI seamlessly into your business operations. AI
                Integration ensures that the AI models are connected across all
                functions, delivering powerful insights and results.
              </p>
              <div className="bold h4 text-blue">AI Integration</div>
            </div>
          </div>
          <small className="usr-timeline-note-text">
            Note: Timeline for sample industry with sample data. Actual timeline
            may vary depending on the data availability.
          </small>
        </div>
      </section>
    );
  };

  const renderDonutChart = () => {
    const report = getRevenueReport();
    if (report) return <PredDonutChart data={report} center={renderCenter} />;
  };

  const getAdditionalProfit = () => {
    return (
      (getRevenueIncrease() +
        getManpowerBenefit() +
        getMaterialCostIncrease()) *
      constant.Million
    );
  };

  const getMaterialCostIncrease = () =>
    revenueReport.before.inventory_benefit -
    revenueReport.after.inventory_benefit;

  const getRevenueIncrease = () =>
    revenueReport.after.revenue_increase -
    revenueReport.before.revenue_increase;

  const getManpowerBenefit = () =>
    revenueReport.before.manpower_benefit -
    revenueReport.after.manpower_benefit;

  const getOverAllExpenses = () =>
    Number(revenueReport.before.revenue_increase) +
    Number(revenueReport.before.manpower_benefit) +
    Number(revenueReport.before.inventory_benefit);

  const renderCenter = (cx, cy) => {
    console.log(getOverAllExpenses());
    const additionalProfit = getAdditionalProfit();
    if (additionalProfit) {
      const percentage = calculatePercentage(
        additionalProfit,
        getOverAllExpenses() * constant.Million
      ).toFixed(2);
      const dimensions = isMobileScreen
        ? constant.donutDimension.mobile
        : constant.donutDimension.desktop;

      return (
        <>
          <text
            x={cx - dimensions.centerXOffset}
            y={cy - dimensions.centerYOffset}
            dy={8}
            textAnchor="middle"
            fill="#000000"
            fontSize={isMobileScreen ? 20 : 24}
            fontWeight="bold"
          >
            <tspan>Additional Profit*</tspan>
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 5 : 15)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={isMobileScreen ? 20 : 28}
            fontWeight="bold"
          >
            {formatToCurrency(additionalProfit)}
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 30 : 35)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={18}
            fontWeight="bold"
          >
            {`(${percentage}%)`}
          </text>
        </>
      );
    }
  };

  const getRevenueReport = () => {
    return [
      {
        name: "revenue Increase",
        value: revenueReport.after.revenue_increase * constant.Million,
      },
      {
        name: "material cost reduction",
        value: revenueReport.after.inventory_benefit * constant.Million,
      },
      {
        name: "manpower efficiencies",
        value: revenueReport.after.manpower_benefit * constant.Million,
      },
    ];
  };
  const renderOpportunities = () => {
    const additionalProfit = getAdditionalProfit();
    return (
      <>
        <div id="companyDetailedReport" ref={reportRef}>
          <header className="header2 bg-blue border-bottom px-3 py-4 ">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-white">
                  <h3 className=" text-white ">
                    Additional Profit Report for{" "}
                    <b className="capitalize">{userInfo.org.org_name}</b>.{" "}
                  </h3>
                  <div>{formatDate(null, "DD.MM.YYYY")}</div>
                </div>
                <img src={images.unsigned.imgLogo2} alt="" />
              </div>
            </div>
          </header>

          <section className="position-relative">
            <div>
              <img src={images.unsigned.imgBanner2} alt="" className="w-100" />
            </div>
            <div className="bannertext text-white bg-blue text-center px-5 py-4">
              <div className="h5">Additional Profit</div>
              <div className="h1 mb-0 text-white bold">
                {formatToCurrency(additionalProfit)}
              </div>
            </div>
          </section>

          <section className="py-5 mb-5">
            <div className="container">
              <div className="resultbox mx-auto d-flex align-items-center text-center">
                <div className="py-4">
                  <div>3-Year ROI</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.roi
                      ? revenueReport.roi?.toFixed(2.2) + "%"
                      : "-"}
                  </h4>
                </div>
                <div className="py-4">
                  <div>3-Month Delay Cost</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.threemonth_deplay_cost
                      ? formatToCurrency(
                          revenueReport.threemonth_deplay_cost *
                            constant.Million
                        )
                      : "-"}
                  </h4>
                </div>
                <div className="py-4">
                  <div>Payback Period</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.payback_period
                      ? Math.ceil(revenueReport.payback_period) + " Mos"
                      : "-"}
                  </h4>
                </div>
              </div>
              <h3 className="pt-5 pb-4 text-center mt-4 lineheight20">
                $62.1K investment over 3 years yields over $11.9M in benefit
                over that same period.
              </h3>
            </div>
            {renderDonutChart()}
          </section>
          {renderAiTimeline()}
          {solutionList && solutionList.length > 0 && (
            <section className="py-5 text-center">
              <div className="container">
                <h3 className="text-center mb-4">
                  AI Solutions for{" "}
                  <span className="text-blue">Agriculture</span>
                </h3>
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <ul className="resullist">{renderAiSolutions()}</ul>
                  </div>
                </div>
                <div className="pt-5 mt-4">
                  <h2 className="bold mb-4 text-blue">Avtar AI</h2>
                  <p className="h4 lineheight10">
                    {" "}
                    is our AI driven application to address Industry-specific
                    problems and make proper predictions or recommendations to
                    solve them
                  </p>
                </div>
              </div>
            </section>
          )}

          <section className="py-5 mb-5 border bg-light">
            <div className="container d-flex flex-column">
              <h3 className="text-center text-blue mb-4">
                Preview of <span className="bold">Avtar AI</span>
              </h3>
              <div>
                <img
                  src={images.unsigned.imgDashboard}
                  alt=""
                  className="w-100"
                />
              </div>
              {renderAutoCreateButton()}
            </div>
          </section>
        </div>
      </>
    );
  };

  const renderAutoCreateButton = () => {
    return (
      <Link
        onClick={() => onCreateWorkflow()}
        className="btn1 m-3 arrowhover d-flex align-items-center align-self-center justify-content-between font-weight-bold w-40"
      >
        Create New Workflow
        <span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 45 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
              fill="white"
            />
          </svg>
        </span>
      </Link>
    );
  };

  const renderBODetailList = () => {
    const temp = bo.map((item, index) => {
      const { key, value } = item;
      return (
        <div
          key={`tab-${index}`}
          className={`tab-pane fade show ${index === 0 ? "active" : ""}`}
          id={`${key.replaceAll(" ", "_")}`}
          role="tabpanel"
          aria-labelledby={`${key}-tab`}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      );
    });
    temp.push(
      <div
        key={"ai-opertunity"}
        className="tab-pane fade"
        id="AIOpportunity"
        role="tabpanel"
        aria-labelledby="AIOpportunity-tab"
      >
        <div className="d-flex flex-column gap-2 justify-content-center">
          {renderAutoCreateButton()}
          {renderOpportunities()}
        </div>
      </div>
    );
    return temp;
  };




  const renderBOContent = () => {
    if (bo && bo.length) {
      return (
        <div className="tab-content mb-5" id="myTabContent">
          <>{renderBODetailList()}</>
        </div>
      );
    }
    return <p>Loading...</p>;
  };


  const closeTourGuide = () => {
    setTourGuide({
      showAlert: false,
      showGuide: false,
      step1: false,
      step2: false,
      step3: false,
    });
  };


  const render = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <div className="px-5 py-3">
          {/*
          <div className="d-flex justify-content-end">
            {renderPrintOption()}
          </div>
           */}

          <div className="d-flex justify-content-between my-3 align-items-center">
            <h2 className="h5 font-weight-bold capitalize">
              {selectedCompany.name || userInfo.org.org_config.companyName}
            </h2>
            {/* {renderCompetitorList()} */}
          </div>
          {/* <div className="d-flex justify-content-end">
            <Link onClick={() => generatePDF()}>
              Download Company's Business Overview
              <FontAwesomeIcon icon={faDownload} className="text-blue mx-1" />
            </Link>
          </div> */}
          <div className="aicompanysec mt-4">
            {renderBOHeader()}
            {renderBOContent()}
            {/* {tourGuide.showAlert && renderTourGuide()}
            {renderTourSteps()} */}
          </div>
        </div>
      </>
    );
  };

  return render();
};

export default BusinessOverview;
