import aiWorkFlow from "./../../assets/images/AI-workflow.svg";
import dashbord from "./../../assets/images/dashbord.svg";
import aiStrategy from "./../../assets/images/AI-Strategy.svg";
import aiValue from "./../../assets/images/AI-value.svg";
import aiRoadmap from "./../../assets/images/AI-roadmap.svg";
import aiTransformation from "./../../assets/images/AI-Transformation.svg";
import aiModel from "./../../assets/images/AI-model.svg";
import aiApplication from "./../../assets/images/AI-application.svg";
import aiOps from "./../../assets/images/AI-ops.svg";
import sidepanelArrow from "./../../assets/images/sidepanelarrow.svg";
import aiCompinfoRevenue from "./../../assets/images/revenue.svg";
import aiCompinfoInventory from "./../../assets/images/inventory.svg";
import aiCompinfoEmployee from "./../../assets/images/employees.svg";
import aiCompinfoChurn from "./../../assets/images/churn.svg";
import avatar from "./../../assets/images/avtar.svg";
import topArrow from "./../../assets/images/toparrow.svg";
import downArrow from "./../../assets/images/downarrow.svg";
import downloadIcon from "./../../assets/images/download.svg";
import aiPrediction from "./../../assets/images/flowprocess.svg";
import singleDataFormImg from "./../../assets/images/SingleDateInput.svg";
import speechFormImg from "./../../assets/images/SpeechText.svg";
import videoFormImg from "./../../assets/images/ImageVideoInput.svg";
import csvFormImg from "./../../assets/images/UploadCSVFile.svg";
import downloadTemplate from "./../../assets/images/FileArrowDown.svg";
import recordImg from "./../../assets/images/Microphone.svg";
import aiTaskDivider from "./../../assets/images/divider.svg";
import aiEditIcon from "./../../assets/images/edit.svg";
import closeWhiteIcon from "./../../assets/images/close.svg";
import arrowLeft from "./../../assets/images/Arrow-left.svg";
import searchIcon from "./../../assets/images/search.svg";
import editModel from "./../../assets/images/edit-model.svg";
import runningModel from "./../../assets/images/running-model.svg";
import completedModel from "./../../assets/images/completed-model.svg";
import businessOverview from "./../../assets/images/business-overview.svg";
import incorpLogo from "./../../assets/images/header-incorp-logo.svg";
import iconPDF from "./../../assets/images/pdf.svg";
import donutChart from "./../../assets/images/donut-chart.svg";
import zohoIcon from "./../../assets/images/zoho.svg";

const images = {
  incorpLogo,
  searchIcon,
  linkedIn: require("./../../assets/images/linkedin.png"),
  companyLogo: require("./../../assets/images/company-logo.png"),
  rightImg: require("./../../assets/images/rightimg.png"),
  aiStep1: require("./../../assets/images/icon1.png"),
  aiStep2: require("./../../assets/images/icon2.png"),
  aiStep3: require("./../../assets/images/icon3.png"),
  aiStep4: require("./../../assets/images/icon4.png"),
  pipeDept: require("./../../assets/images/pipe-dept.png"),
  recentModels: require("./../../assets/images/image1.png"),
  industryAgnostic: require("./../../assets/images/image2.png"),
  aiTickInon: require("./../../assets/images/tick.png"),
  uploadIcon: require("./../../assets/images/upload.png"),
  bannerImg: require("./../../assets/images/banner1.jpg"),
  workflow: require("./../../assets/images/workflow.png"),
  visa: require("./../../assets/images/visa.png"),
  task1: require("./../../assets/images/task1.png"),
  task2: require("./../../assets/images/task2.png"),
  task3: require("./../../assets/images/task3.png"),
  loaderGif: require("./../../assets/images/loader.gif"),
  draftImage: require("./../../assets/images/cylinder-inactive.png"),
  downloadIcon,
  donutChart,
  aiEditIcon,
  aiTaskDivider,
  aiWorkFlow,
  dashbord,
  aiStrategy,
  aiValue,
  aiRoadmap,
  aiTransformation,
  aiModel,
  aiApplication,
  aiOps,
  sidepanelArrow,
  aiCompinfoRevenue,
  aiCompinfoInventory,
  aiCompinfoEmployee,
  aiCompinfoChurn,
  iconPDF,
  avatar,
  closeWhiteIcon,
  runningModel,
  editModel,
  completedModel,
  businessOverview,
  aiValueBreakdown: {
    topArrow,
    downArrow,
    revenueIncrease: require("./../../assets/images/growth1.png"),
    materialCost: require("./../../assets/images/low-prices.png"),
    manpowerEff: require("./../../assets/images/worker.png"),
    manchineCost: require("./../../assets/images/growth4.png"),
  },
  aiPrediction,
  page404: require("./../../assets/images/error.png"),
  aiDataForm: {
    singleDataFormImg,
    speechFormImg,
    videoFormImg,
    csvFormImg,
    downloadTemplate,
    recordImg,
  },
  unsigned: {
    revenueIncrease: require("./../../assets/images/Revenue-Increase.png"),
    revenueFlow: require("./../../assets/images/flow1.png"),
    materialCost: require("./../../assets/images/Material-Cost-Reduction.png"),
    materialFlow: require("./../../assets/images/flow2.png"),
    manPowerEff: require("./../../assets/images/Manpower-Efficiencies.png"),
    manPowerFlow: require("./../../assets/images/flow3.png"),
    manchineCost: require("./../../assets/images/Machine-Cost-Reduction.png"),
    manchineFlow: require("./../../assets/images/flow4.png"),
    imgBanner2: require("./../../assets/images/banner2.jpg"),
    imgLogo2: require("./../../assets/images/logo2.png"),
    imgTimeline: require("./../../assets/images/timeline.png"),
    imgDashboard: require("./../../assets/images/dashboard.png"),
  },
  socialMediaResult: {
    arrowLeft,
    logo: require("./../../assets/images/logo.png"),
    googleReview: require("./../../assets/images/1.png"),
    salesReport: require("./../../assets/images/2.png"),
    customerFeeling: require("./../../assets/images/3.png"),
    advertise0: require("./../../assets/images/slide0.png"),
    advertise1: require("./../../assets/images/slide1.png"),
    advertise2: require("./../../assets/images/slide2.png"),
    reviewList: require("./../../assets/images/image4.png"),
    adLogo1: require("./../../assets/images/popeyes1.jpg"),
    adLogo2: require("./../../assets/images/popeyes2.jpg"),
    adLogo3: require("./../../assets/images/popeyes3.jpg"),
    adLogo4: require("./../../assets/images/popeyes4.jpg"),
  },
  zohoIcon,
};

export default images;
